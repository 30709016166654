import confetti from 'canvas-confetti';

import { Confirmation as Props } from './confirmation.props';

export const options: Record<NonNullable<Props['mood']>, confetti.Options[]> = {
  celebratory: [
    {
      origin: { y: 0.7 },
      particleCount: 100,
      spread: 26,
      startVelocity: 55,
    },
    {
      origin: { y: 0.7 },
      particleCount: 80,
      spread: 60,
    },
    {
      decay: 0.91,
      origin: { y: 0.7 },
      particleCount: 140,
      scalar: 0.8,
      spread: 100,
    },
    {
      decay: 0.92,
      origin: { y: 0.7 },
      particleCount: 40,
      scalar: 1.2,
      spread: 120,
      startVelocity: 25,
    },
    {
      origin: { y: 0.7 },
      particleCount: 40,
      spread: 120,
      startVelocity: 45,
    },
  ],
};
