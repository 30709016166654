import React from 'react';

import Link from '@sats-group/ui-lib/react/link';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import { ProceedWithLead as Props } from './proceed-with-lead.props';

const ProceedWithLead: React.FC<Props> = ({
  description,
  proceed,
  title,
  startOver,
  subtitle,
  userName,
}) => (
  <div className="proceed-with-lead">
    <h2>{title}</h2>
    <div className="proceed-with-lead__body">
      <Text>{subtitle}</Text>
      <TextInput {...userName} disabled />
      <Text>{description}</Text>
    </div>
    <div className="proceed-with-lead__navigation">
      <Link {...startOver} />
      <LinkButton {...proceed} />
    </div>
  </div>
);

export default ProceedWithLead;
