import confetti from 'canvas-confetti';
import React, { useEffect, useRef } from 'react';

import isRunningOnClient from '@sats-group/ui-lib/react/hooks/is-running-on-client';
import LinkButton from '@sats-group/ui-lib/react/link-button/link-button';
import SvgElixia from '@sats-group/ui-lib/react/logos/elixia';
import Sats from '@sats-group/ui-lib/react/logos/sats';
import Message from '@sats-group/ui-lib/react/message/message';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'shared/components/content-container/content-container';

import type { NamedFC } from 'shared-ui/named-fc.types';

import { options } from './confetti-options';
import { Confirmation as Props } from './confirmation.props';

const Confirmation: NamedFC<Props> = ({
  back,
  mood,
  disclaimer,
  documentationDisclaimer,
  information,
  items,
  language,
  message,
  redirectUrl,
  title,
}) => {
  const animationCanvas = useRef(null);
  const localConfetti = useRef<ReturnType<(typeof confetti)['create']>>(null);

  if (isRunningOnClient) {
    setTimeout(() => window.location.replace(redirectUrl), 300000);
  }

  useEffect(() => {
    if (!animationCanvas.current) {
      return;
    }

    if (!mood) {
      return;
    }

    if (!localConfetti.current) {
      // NOTE: The type is wrong.
      // `.current` is not read-only.
      //
      // > This value is intentionally mutable,
      // > meaning you can both read and write to it.
      // > It’s like a secret pocket of your component that React doesn’t track.
      //
      // - https://react.dev/learn/referencing-values-with-refs
      //
      // @ts-expect-error
      localConfetti.current = confetti.create(animationCanvas.current, {
        disableForReducedMotion: true,
        resize: true,
        useWorker: true,
      });
    }

    options[mood].forEach(option => {
      if (localConfetti.current) {
        localConfetti.current(option);
      }
    });

    return () => {
      if (localConfetti.current) {
        localConfetti.current.reset();
      }
    };
  }, []);

  return (
    <div className="confirmation-page">
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div className="confirmation-page__logo">
          {language === 'fi' ? <SvgElixia /> : <Sats />}
        </div>
        {message ? (
          <div className="confirmation-page__message">
            <Message {...message} />
          </div>
        ) : null}
        {items.length ? (
          <div className="confirmation-page__summary">
            <Text
              theme={Text.themes.headline}
              elementName="h1"
              size={Text.sizes.headline2}
              italic={true}
            >
              {title}
            </Text>
            <Text>{information}</Text>
            <Text size={Text.sizes.interface}>{disclaimer}</Text>

            <div>
              {items.map(({ title, value }) => (
                <div className="confirmation-page__line-item" key={title}>
                  <Text theme={'emphasis'}>{title}</Text>
                  <Text>{value}</Text>
                </div>
              ))}
            </div>

            <div className="confirmation-page__documentation">
              <Text theme={Text.themes.emphasis}>
                {documentationDisclaimer.title}
              </Text>
              <Text>{documentationDisclaimer.text}</Text>
            </div>
          </div>
        ) : null}
      </ContentContainer>
      <div className="confirmation-page__link">
        <LinkButton {...back} variant={LinkButton.variants.primary} />
      </div>
      <div className="confirmation-page__background"></div>
      <aside className="confirmation-page__animation" role="presentation">
        <canvas ref={animationCanvas} />
      </aside>
    </div>
  );
};

Confirmation.displayName = 'Confirmation';

export default Confirmation;
