import cn from 'classnames';
import React, { useState } from 'react';
import Collapse from 'react-tiny-collapse';

import Check from '@sats-group/icons/16/check';
import Close from '@sats-group/icons/16/close';
import SvgArrowDown from '@sats-group/icons/24/arrow-down';
import SvgArrowUp from '@sats-group/icons/24/arrow-up';
import Button from '@sats-group/ui-lib/react/button/button';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Tag from '@sats-group/ui-lib/react/tag';
import Text from '@sats-group/ui-lib/react/text';

import { MembershipCard as Props } from './membership-card.props';

const MembershipCard: React.FunctionComponent<Props> = ({
  adjustedPrice,
  benefits,
  close,
  description,
  drawbacks,
  highlighted,
  id,
  link,
  name,
  open,
  price,
  productsTitle,
  systemId,
  tag,
  unit,
}) => {
  const [isOpen, setIsOpen] = useState(highlighted);

  return (
    <div
      className={cn('membership-card', {
        'membership-card--highlighted': highlighted,
      })}
      data-id={id}
      data-system-id={systemId}
    >
      {tag ? (
        <div className="membership-card__tag">
          <Tag size={Tag.sizes.xs} variant={Tag.variants.tertiary} {...tag} />
        </div>
      ) : null}
      <div className="membership-card__descriptions">
        <div>
          <div className="membership-card__name-wrapper">
            <Text
              size={Text.sizes.headline3}
              theme={highlighted ? Text.themes.emphasis : Text.themes.normal}
            >
              {name}
            </Text>
            <div className="membership-card__trigger">
              <Button
                ariaLabel={isOpen ? close : open}
                aria-expanded={isOpen}
                leadingIcon={isOpen ? <SvgArrowUp /> : <SvgArrowDown />}
                onClick={() => setIsOpen(isOpen => !isOpen)}
                size={Button.sizes.small}
                variant={Button.variants.secondary}
              />
            </div>
          </div>
          {description ? (
            <Text className="membership-card__description">{description}</Text>
          ) : null}
        </div>
        <div className="membership-card__price-wrapper">
          {adjustedPrice ? (
            <div className="membership-card__adjusted-price">
              <Text size={Text.sizes.headline1} theme={Text.themes.emphasis}>
                {adjustedPrice}
              </Text>
              <Text
                className="membership-card__unit"
                theme={Text.themes.emphasis}
              >
                {unit}*
              </Text>
            </div>
          ) : null}
          <div className="membership-card__price">
            {adjustedPrice ? (
              <Text
                size={Text.sizes.basic}
                theme={Text.themes.normal}
                className="membership-card__reduced-price"
              >
                {price} {unit}
              </Text>
            ) : (
              <>
                <Text size={Text.sizes.headline1} theme={Text.themes.emphasis}>
                  {price}
                </Text>
                <Text
                  className="membership-card__unit"
                  theme={Text.themes.emphasis}
                >
                  {unit}
                </Text>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={cn('membership-card__products', {
          'membership-card__products--open': isOpen,
        })}
      >
        {benefits || drawbacks ? (
          <Collapse
            isOpen={isOpen}
            className="membership-card__collapse"
            duration={200}
          >
            <div className="membership-card__products-content">
              <hr className="membership-card__divider" />
              <div>
                <Text
                  className="membership-card__products-list-title"
                  theme={Text.themes.emphasis}
                >
                  {productsTitle}
                </Text>
                <div className="membership-card__products-list-wrapper">
                  {benefits ? (
                    <div className="membership-card__products-list">
                      {benefits.map(product => (
                        <div key={product} className="membership-card__product">
                          <div className="membership-card__benefit-icon">
                            <Check />
                          </div>
                          <Text size={Text.sizes.small}>{product}</Text>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {drawbacks ? (
                    <div className="membership-card__products-list">
                      {drawbacks.map(product => (
                        <div key={product} className="membership-card__product">
                          <div className="membership-card__drawback-icon">
                            <Close />
                          </div>
                          <Text
                            className="membership-card__drawback"
                            size={Text.sizes.small}
                          >
                            {product}
                          </Text>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Collapse>
        ) : null}
      </div>
      <div className="membership-card__button">
        <LinkButton
          {...link}
          size={highlighted ? 'large' : 'basic'}
          variant={highlighted ? 'primary' : 'secondary'}
          wide={true}
          data-test-select-membership
        />
      </div>
    </div>
  );
};

export default MembershipCard;
