import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input/hidden-input';
import LogoSats from '@sats-group/ui-lib/react/logos/sats';
import Message from '@sats-group/ui-lib/react/message/message';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input/text-input';

import ContentContainer from 'shared/components/content-container/content-container';

import Spinner from 'shared-ui/components/spinner/spinner';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { Login as Props } from './login.props';
import { login } from './login.texts';

const Login: NamedFC<Props> = ({
  endpoint,
  hiddenInputs = [],
  layout,
  messages,
}) => {
  const texts = login[layout.language];

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    if (isLoading) {
      e.preventDefault();
    }

    setIsLoading(true);
  };

  return (
    <div className="login-page">
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div className="login-page__card">
          <div className="login-page__logo">
            <LogoSats />
          </div>
          <Text
            className="login-page__info"
            elementName="h1"
            size={Text.sizes.headline3}
          >
            {texts.title}
          </Text>
          <div className="login-page__info">
            <Text>{texts.labels.description}</Text>
          </div>
          {messages.length ? (
            <div className="login-page__messages">
              {messages.map(message => (
                <Message key={message.text} {...message} />
              ))}
            </div>
          ) : null}
          <form action={endpoint} method="post" onSubmit={handleSubmit}>
            {isLoading ? <Spinner theme={Spinner.themes.overlay} /> : null}
            {hiddenInputs.map(input => (
              <HiddenInput {...input} key={input.name} />
            ))}
            <div className="login-page__inputs">
              <div key="user">
                <TextInput
                  name="user"
                  label={texts.labels.username}
                  required={true}
                  placeholder={texts.labels.usernamePlaceholder}
                />
              </div>
              <div key="password">
                <TextInput
                  name="password"
                  label={texts.labels.password}
                  required={true}
                  type="password"
                  placeholder={texts.labels.passwordPlaceholder}
                />
              </div>
            </div>
            <div className="login-page__actions">
              <Button
                type="submit"
                text={texts.submitLabel}
                size={Button.sizes.large}
              />
            </div>
          </form>
          {/* finish up the reset password stuff here */}
          {/* <div className="login-page__reset">
            <CleanLink
              href={urls.resetPassword(layout.language)}
              text={texts.labels.forgotPassword}
            />
          </div> */}
        </div>
      </ContentContainer>
    </div>
  );
};

Login.displayName = 'Login';

export default Login;
