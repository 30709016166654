import React from 'react';

import ArrowRight from '@sats-group/icons/24/arrow-right';
import Tag from '@sats-group/ui-lib/react/tag';
import Text from '@sats-group/ui-lib/react/text';

import { Club as Props } from './club.props';

const Club: React.FunctionComponent<Props> = ({ id, link, name, region }) => (
  <a className="club" href={link} data-id={id}>
    <div className="club__left">
      <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
        {name}
      </Text>
      {region ? (
        <div className="club__tag">
          <Tag text={region} />
        </div>
      ) : null}
    </div>
    <div className="club__icon">
      <ArrowRight />
    </div>
  </a>
);

export default Club;
