import React, { useState } from 'react';

import Checkbox from '@sats-group/ui-lib/react/checkbox';
import isRunningOnClient from '@sats-group/ui-lib/react/hooks/is-running-on-client';
import Text from '@sats-group/ui-lib/react/text';

import OptionLinks from 'functions/customize-membership/components/option-links/option-links';

import CheckoutSummary from 'shared-ui/components/checkout-summary/checkout-summary';
import Discountcode from 'shared-ui/components/discount-code/discount-code';
import Layout from 'shared-ui/components/layout/layout';
import Spinner from 'shared-ui/components/spinner/spinner';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { CustomizeMembership as Props } from './customize-membership.props';

const CustomizeMembership: NamedFC<Props> = ({
  campaignCode,
  corporateCode,
  checkoutSummary,
  addons,
  layout,
  options = [],
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Layout {...layout}>
      <div className="customize-membership">
        {isLoading ? <Spinner theme={Spinner.themes.overlay} /> : null}
        <div className="customize-membership__options">
          {options.map(option => (
            <div
              className="customize-membership__option-category"
              key={option.title}
            >
              <Text
                elementName="h2"
                size={Text.sizes.large}
                theme={Text.themes.emphasis}
              >
                {option.title}
              </Text>
              <OptionLinks {...option.options} />
            </div>
          ))}

          {addons ? (
            <div className="customize-membership__option-category">
              <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
                {addons.title}
              </Text>
              {addons.options.map(({ link, option }) => (
                <div className="customize-membership__addon" key={link}>
                  <Checkbox
                    theme={Checkbox.themes.blue}
                    {...option}
                    onClick={() => {
                      if (isRunningOnClient) {
                        setIsLoading(true);
                        window.location.assign(link);
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className="customize-membership__discounts">
          {campaignCode ? <Discountcode {...campaignCode} /> : null}
          {corporateCode ? <Discountcode {...corporateCode} /> : null}
        </div>
        <div className="customize-membership__summary">
          {checkoutSummary ? <CheckoutSummary {...checkoutSummary} /> : null}
        </div>
      </div>
    </Layout>
  );
};

CustomizeMembership.displayName = 'CustomizeMembership';

export default CustomizeMembership;
