import cn from "classnames";
import React from "react";

import { ThemedComponent } from "../../types/types";

import type { ContentContainer as Props } from "./content-container.types";
import { themes } from "./content-container.types";

const ContentContainer: ThemedComponent<
	React.PropsWithChildren<Props>,
	typeof themes
> = ({ children, className, raw, theme, ...rest }: Props) => (
	<div
		className={cn(
			"content-container",
			{
				"content-container--raw": raw,
				[`content-container--${theme}`]: theme,
			},
			className,
		)}
		{...rest}
	>
		{children}
	</div>
);

ContentContainer.themes = themes;

export default ContentContainer;
