import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Collapse from 'react-tiny-collapse';

import ArrowDown from '@sats-group/icons/16/arrow-down';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import useClickOutside from '@sats-group/ui-lib/react/hooks/use-click-outside';
import useEscape from '@sats-group/ui-lib/react/hooks/use-escape';
import Search from '@sats-group/ui-lib/react/search/search';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import type {
  PrefixPickerOption,
  PhoneInput as Props,
} from './phone-input.types';

const PhoneInput: React.FunctionComponent<Props> = ({
  prefixInputName,
  language,
  prefixPicker,
  search,
  title,
  ...inputProps
}) => {
  const defaultDisplayValue =
    prefixPicker.find(option => option.defaultValue)?.displayValue || '';
  const defaultDialCode =
    prefixPicker.find(option => option.defaultValue)?.dialCode || '';
  const [displayValue, setDisplayValue] = useState(defaultDisplayValue);
  const [value, setValue] = useState(defaultDialCode);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState<PrefixPickerOption[]>(prefixPicker);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchTerm.length) {
      setOptions(
        prefixPicker.filter(option =>
          `${option.name} ${option.dialCode}`
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        )
      );
    } else {
      setOptions(prefixPicker);
    }
  }, [searchTerm]);

  useClickOutside(ref, () => setIsDropdownOpen(false));
  useEscape(() => setIsDropdownOpen(false));

  const handleDialCodeSelect = (selectedOption: PrefixPickerOption) => {
    setDisplayValue(selectedOption.displayValue);
    setValue(selectedOption.dialCode);
    setIsDropdownOpen(false);
  };

  return (
    <fieldset className="phone-input">
      <HiddenInput name={prefixInputName} value={value} />
      {language && title ? (
        <legend className="phone-input__label">
          <Text theme={Text.themes.emphasis} size={Text.sizes.small}>
            {title}
          </Text>
          <Text
            className="phone-input__label-asterisk"
            theme={Text.themes.emphasis}
            size={Text.sizes.small}
          >
            *
          </Text>
        </legend>
      ) : null}
      <div className="phone-input__wrapper">
        <div className="phone-input__prefix-picker" ref={ref}>
          <button
            type="button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={cn('phone-input__trigger', {
              'phone-input__trigger--open': isDropdownOpen,
            })}
          >
            <div className="phone-input__display-value">{displayValue}</div>
            <div
              className={cn('phone-input__icon', {
                'phone-input__icon--flipped': isDropdownOpen,
              })}
            >
              <ArrowDown />
            </div>
          </button>
          <Collapse
            className="phone-input__dropdown"
            isOpen={isDropdownOpen}
            duration={200}
          >
            <div>
              <div className="phone-input__search">
                <Search
                  {...search}
                  inputSize={Search.inputSizes.small}
                  value={searchTerm}
                  onChangeFunc={e => setSearchTerm(e)}
                />
              </div>
              <div className="phone-input__dropdown-list">
                {options.map(dial => (
                  <button
                    type="button"
                    key={dial.displayValue}
                    onClick={() => handleDialCodeSelect(dial)}
                    className={cn('phone-input__dropdown-item', {
                      'phone-input__dropdown-item--default-list':
                        searchTerm.length < 1,
                    })}
                  >
                    <Text>{dial.emoji}</Text>
                    <Text size={Text.sizes.small}>{dial.name}</Text>
                    <Text
                      className="phone-input__dial-code"
                      size={Text.sizes.small}
                    >
                      {dial.dialCode}
                    </Text>
                  </button>
                ))}
              </div>
            </div>
          </Collapse>
        </div>
        <div className="phone-input__input">
          <TextInput {...inputProps} />
        </div>
      </div>
    </fieldset>
  );
};

export default PhoneInput;
