import React, { useRef, useState } from 'react';

import isRunningOnClient from '@sats-group/ui-lib/react/hooks/is-running-on-client';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import useAsyncEffect from 'functions/payment/hooks/use-async-effect';
import getAdyenLocale from 'functions/payment/utils/get-adyen-locale';

import CheckoutSummary from 'shared-ui/components/checkout-summary/checkout-summary';
import Layout from 'shared-ui/components/layout/layout';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { useAdyen, type Core } from './hooks/use-adyen';
import { Payment as Props } from './payment.props';

const Payment: NamedFC<Props> = ({
  adyenClientKey,
  adyenDisclaimer,
  adyenEnvironment,
  adyenTitle,
  checkoutSummary,
  consent,
  description,
  layout,
  messages = [],
  onSuccessUrl,
  parkSession,
  paymentErrorMessage,
  paymentModuleLoadingErrorMessage,
  paymentRefusedMessage,
  paymentSummary,
  session,
}) => {
  const [paymentMessages, setPaymentMessages] = useState(messages);
  const checkoutRef = useRef<Core>();

  const [, hasAdyenLoaded] = useAdyen(() =>
    setPaymentMessages([
      ...paymentMessages,
      {
        text: paymentModuleLoadingErrorMessage,
        theme: 'error',
      },
    ])
  );

  useAsyncEffect(async () => {
    if (!hasAdyenLoaded || !window.AdyenCheckout || !onSuccessUrl) {
      return;
    }

    if (!session) {
      return;
    }

    const adyenConfiguration: Parameters<typeof window.AdyenCheckout>[0] = {
      environment: adyenEnvironment, // Change to 'live' for the live environment.
      clientKey: adyenClientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      locale: getAdyenLocale(layout.language),
      session: session,
      onPaymentCompleted: response => {
        switch (response.resultCode) {
          case 'Authorised': {
            if (isRunningOnClient) {
              window.location.assign(onSuccessUrl);
              return;
            }
            return;
          }
          case 'Refused': {
            setPaymentMessages([
              {
                text: paymentRefusedMessage,
                theme: 'error',
              },
            ]);

            if (checkoutRef.current) {
              checkoutRef.current.update();
            }

            break;
          }
          case 'Error':
          default: {
            setPaymentMessages([
              {
                text: paymentErrorMessage,
                theme: 'error',
              },
            ]);

            if (checkoutRef.current) {
              checkoutRef.current.update();
            }
          }
        }
      },
      onError: () => {
        setPaymentMessages([
          {
            text: paymentErrorMessage,
            theme: 'error',
          },
        ]);

        if (checkoutRef.current) {
          checkoutRef.current.update();
        }
      },
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration: {
        card: {
          challengeWindowSize: '05',
          name: adyenTitle,
          hasHolderName: true,
          holderNameRequired: true,
        },
      },
    };

    const checkout = await window.AdyenCheckout(adyenConfiguration);
    checkout.create('dropin').mount(`#dropin-container`);
    checkoutRef.current = checkout;
    setPaymentMessages([]);
  }, [hasAdyenLoaded]);

  return (
    <Layout messages={paymentMessages} {...layout}>
      <div className="payment">
        <div className="payment__disclaimer">
          <Text size={Text.sizes.interface}>{description}</Text>
          {paymentSummary ? (
            <div className="payment__summary">
              {paymentSummary.map(({ label, value }) => (
                <div key={label} className="payment__summary-line">
                  <Text size={Text.sizes.interface}>{label}</Text>
                  <Text size={Text.sizes.interface}>{value}</Text>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div id="dropin-container"></div>
        {parkSession ? (
          <div className="payment__park">
            <LinkButton
              variant={LinkButton.variants.secondary}
              wide
              {...parkSession}
            />
          </div>
        ) : null}
        <div className="payment__consent">
          <Text size={Text.sizes.interface}>{consent}</Text>
          <Text
            className="payment__adyen-disclaimer"
            size={Text.sizes.interface}
          >
            {adyenDisclaimer}
          </Text>
        </div>
        <div className="payment__checkout-summary">
          {checkoutSummary ? <CheckoutSummary {...checkoutSummary} /> : null}
        </div>
      </div>
    </Layout>
  );
};

Payment.displayName = 'Payment';

export default Payment;
