import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import Link from '@sats-group/ui-lib/react/link';
import Message from '@sats-group/ui-lib/react/message/message';
import Modal from '@sats-group/ui-lib/react/modal';
import Text from '@sats-group/ui-lib/react/text';
import TextArea from '@sats-group/ui-lib/react/text-area';

import ContentContainer from 'shared/components/content-container/content-container';

import Logo from 'shared-ui/components/logo/logo';
import type { NamedFC } from 'shared-ui/named-fc.types';

import { LeadConfirmation as Props } from './lead-confirmation.props';
import ProceedWithLead from './proceed-with-lead/proceed-with-lead';

const LeadConfirmation: NamedFC<Props> = ({
  becomeMember,
  buttonText,
  description,
  endpoint,
  hiddenInputs,
  link,
  logo,
  message,
  modal,
  proceedWithLead,
  textArea,
  title,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="lead-confirmation">
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div className="lead-confirmation__logo">
          <Logo {...logo} />
        </div>
        <div className="lead-confirmation__body">
          <Text elementName="h1" size={'headline1'} theme={'headline'} italic>
            {title}
          </Text>
          <Text elementName="p">{description}</Text>
          <Button
            variant={'cta'}
            onClick={() => setShowModal(true)}
            text={becomeMember}
          />
        </div>
        <div className="lead-confirmation__comment">
          {message ? <Message {...message} /> : null}
          {message?.theme === 'success' ? null : (
            <form action={endpoint} method="POST">
              {hiddenInputs.map(hiddenInput => (
                <HiddenInput {...hiddenInput} key={hiddenInput.name} />
              ))}
              <TextArea {...textArea} required />
              <Button
                className="lead-confirmation__form-submit"
                type="submit"
                text={buttonText}
              />
            </form>
          )}
          <div className="lead-confirmation__divider"></div>
          <Link {...link} className="lead-confirmation__back" />
          {showModal ? (
            <Modal {...modal} onClose={() => setShowModal(false)}>
              <ProceedWithLead {...proceedWithLead} />
            </Modal>
          ) : null}
        </div>
      </ContentContainer>
      <div className="lead-confirmation__background"></div>
    </div>
  );
};

LeadConfirmation.displayName = 'LeadConfirmation';

export default LeadConfirmation;
