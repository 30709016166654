import cn from 'classnames';
import React, { useState, useRef } from 'react';
import Collapse from 'react-tiny-collapse';

import SvgArrowUp from '@sats-group/icons/24/arrow-up';
import SvgClose from '@sats-group/icons/24/close';
import SvgEdit from '@sats-group/icons/24/edit';
import Button from '@sats-group/ui-lib/react/button/button';
import useClickOutside from '@sats-group/ui-lib/react/hooks/use-click-outside';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import useOnPageShow from 'utils/use-on-page-show';

import { CheckoutSummary as Props } from './checkout-summary.props';

const CheckoutSummary: React.FunctionComponent<Props> = ({
  activeCampaign,
  activeCorporateAgreement,
  buttonLabel,
  changeMembership,
  description,
  link,
  priceItems,
  selectedItems,
  sponsorshipType,
  standardPrice,
  submit,
  title,
  totalPrice,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const checkoutSummary = cn('checkout-summary', {
    'checkout-summary--opened': isOpen,
  });

  const handleSubmit = () => {
    setIsSubmitting(true);
    const form = document.getElementById(
      'registration-form'
    ) as HTMLFormElement;
    if (form) {
      if (form.checkValidity()) {
        form.submit();
      } else {
        form.reportValidity();
        setIsSubmitting(false);
      }
    }
  };

  useOnPageShow(setIsSubmitting);
  useClickOutside(ref, () => {
    toggleIsOpen(false);
  });

  return (
    <>
      <div className={checkoutSummary} ref={ref}>
        <Collapse
          isOpen={isOpen}
          className="checkout-summary__collapse"
          duration={200}
        >
          <div>
            <div className="checkout-summary__header">
              <div className="checkout-summary__membership">
                <Text theme={Text.themes.emphasis}>{title}</Text>
                <Text size={Text.sizes.small}>{description}</Text>
              </div>
              <div className="checkout-summary__link">
                <a
                  href={changeMembership.href}
                  aria-label={changeMembership.label}
                  title={changeMembership.label}
                >
                  <SvgEdit />
                </a>
              </div>
            </div>

            <div className="checkout-summary__body">
              {activeCampaign ? (
                <div className="checkout-summary__section">
                  <div className="checkout-summary__line-item">
                    <Text theme={Text.themes.emphasis}>
                      {activeCampaign.title}
                    </Text>
                    <Text>{activeCampaign.value}</Text>
                  </div>
                </div>
              ) : null}

              {activeCorporateAgreement ? (
                <div className="checkout-summary__section">
                  <div className="checkout-summary__line-item">
                    <Text theme={Text.themes.emphasis}>
                      {activeCorporateAgreement.title}
                    </Text>
                    <div className="checkout-summary__line-item-value">
                      <Text>{activeCorporateAgreement.value}</Text>
                      {activeCorporateAgreement.href ? (
                        <a
                          href={activeCorporateAgreement.href}
                          aria-label={activeCorporateAgreement.label}
                          title={activeCorporateAgreement.label}
                        >
                          <SvgClose />
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="checkout-summary__section">
                {selectedItems.map(({ title, value, href, label }) => (
                  <div
                    key={title}
                    className={cn('checkout-summary__line-item', {
                      'checkout-summary__line-item--editable': href && label,
                    })}
                  >
                    <Text theme={Text.themes.emphasis}>{title}</Text>
                    <div className="checkout-summary__line-item-value">
                      <Text>{value}</Text>
                      {href && label ? (
                        <a href={href} aria-label={label} title={label}>
                          <SvgEdit />
                        </a>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>

              <div className="checkout-summary__section">
                {priceItems.map(({ title, value }) => (
                  <div key={title} className="checkout-summary__line-item">
                    <Text theme={Text.themes.emphasis}>{title}</Text>
                    <Text>{value}</Text>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Collapse>

        <div className="checkout-summary__footer">
          <div className="checkout-summary__toggle">
            <button
              aria-label={isOpen ? buttonLabel.close : buttonLabel.open}
              aria-expanded={isOpen}
              className="checkout-summary__button"
              onClick={event => {
                event.stopPropagation();
                toggleIsOpen(!isOpen);
              }}
            >
              <div
                className={cn('checkout-summary__toggle-arrow', {
                  'checkout-summary__toggle-arrow--flipped': isOpen,
                })}
              >
                <SvgArrowUp />
              </div>
              <div className="checkout-summary__price-wrapper">
                {standardPrice ? (
                  <Text
                    className="checkout-summary__standard-price"
                    size={Text.sizes.small}
                  >
                    {standardPrice}
                  </Text>
                ) : null}
                <div className="checkout-summary__price">
                  <Text>{totalPrice.title}</Text>
                  <Text theme={Text.themes.emphasis}>{totalPrice.value}</Text>
                </div>
              </div>
            </button>
            {link ? (
              <div className="checkout-summary__next">
                <LinkButton
                  variant={'complete'}
                  {...link}
                  data-test-next-step
                  size={Button.sizes.large}
                />
              </div>
            ) : null}
            {submit ? (
              <div className="checkout-summary__next">
                <Button
                  type="button"
                  form="registration-form"
                  variant={'complete'}
                  disabled={isSubmitting ? true : undefined}
                  theme={isSubmitting ? 'spinner' : undefined}
                  {...submit}
                  size={Button.sizes.large}
                  onClick={handleSubmit}
                  data-test-next-step
                />
              </div>
            ) : null}
          </div>
        </div>
        {sponsorshipType ? (
          <div className="checkout-summary__sponsorship-type">
            <Text>{sponsorshipType}</Text>
          </div>
        ) : null}
      </div>
      {isOpen ? <div className="checkout-summary__backdrop"></div> : null}
    </>
  );
};

export default CheckoutSummary;
