import { useEffect } from 'react';

const useOnPageShow = (setIsSubmitting: (value: boolean) => void) => {
  useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        setIsSubmitting(false);
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, [setIsSubmitting]);
};

export default useOnPageShow;
